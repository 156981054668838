import {
    AdditionalEmploymentEnumerator,
    BorrowerEnumerator,
    ExistingPropertyEnumerator,
    FinancingOptionEnumerator,
    AdditionalAskedBankEnumerator,
    AdditionalIncomeEnumerator,
    Person,
    DealPropertiesKey,
    RequiredDocumentJobType,
    BorrowerType,
    ObjectType,
    RelatedObjectType,
} from "../models";

export const BORROWER_ENUMERATOR_KEYS: BorrowerEnumerator[] = ["Borrower1", "Borrower2", "Borrower3", "Borrower4"];

export const EXISTING_PROPERTY_ENUMERATOR_KEYS: ExistingPropertyEnumerator[] = [
    "existingProperty1",
    "existingProperty2",
    "existingProperty3",
    "existingProperty4",
];

export const FINANCING_OPTION_ENUMERATOR_KEYS: FinancingOptionEnumerator[] = [
    "Option1",
    "Option2",
    "Option3",
    "Option4",
];

export const ADDITIONAL_EMPLOYMENT_ENUMERATOR_KEYS: AdditionalEmploymentEnumerator[] = [
    "additionalEmployment1",
    "additionalEmployment2",
];

export const ADDITIONAL_INCOME_ENUMERATOR_KEYS: AdditionalIncomeEnumerator[] = [
    "additionalIncome1",
    "additionalIncome2",
    "additionalIncome3",
    "additionalIncome4",
];

export const ADDITIONAL_ASKED_BANKS_ENUMERATOR_KEYS: AdditionalAskedBankEnumerator[] = [
    "AdditionalAskedBank1",
    "AdditionalAskedBank2",
    "AdditionalAskedBank3",
    "AdditionalAskedBank4",
];

export const BORROWER_DIMENSION_TO_MAIN_JOB_NAME_MAP: Record<Person, DealPropertiesKey> = {
    kreditnehmer1: "employerBorrower1",
    kreditnehmer2: "employerBorrower2",
    kreditnehmer3: "employerBorrower3",
    kreditnehmer4: "employerBorrower4",
};

export const BORROWER_DIMENSION_TO_ADDITIONAL_JOB_1_NAME_MAP: Record<Person, DealPropertiesKey> = {
    kreditnehmer1: "additionalEmployment1employerBorrower1",
    kreditnehmer2: "additionalEmployment1employerBorrower2",
    kreditnehmer3: "additionalEmployment1employerBorrower3",
    kreditnehmer4: "additionalEmployment1employerBorrower4",
};

export const BORROWER_DIMENSION_TO_ADDITIONAL_JOB_2_NAME_MAP: Record<Person, DealPropertiesKey> = {
    kreditnehmer1: "additionalEmployment2employerBorrower1",
    kreditnehmer2: "additionalEmployment2employerBorrower2",
    kreditnehmer3: "additionalEmployment2employerBorrower3",
    kreditnehmer4: "additionalEmployment2employerBorrower4",
};

export const BORROWER_DIMENSION_TO_ADDITIONAL_JOBS_COUNT_FIELD_MAP: Record<Person, DealPropertiesKey> = {
    kreditnehmer1: "numberOfAdditionalEmploymentsBorrower1",
    kreditnehmer2: "numberOfAdditionalEmploymentsBorrower2",
    kreditnehmer3: "numberOfAdditionalEmploymentsBorrower3",
    kreditnehmer4: "numberOfAdditionalEmploymentsBorrower4",
};

export const REQUIRED_JOB_TYPE_TO_FIELDS_MAP: Record<RequiredDocumentJobType, Record<Person, DealPropertiesKey>> = {
    [RequiredDocumentJobType.MAIN]: BORROWER_DIMENSION_TO_MAIN_JOB_NAME_MAP,
    [RequiredDocumentJobType.ADDITIONAL_1]: BORROWER_DIMENSION_TO_ADDITIONAL_JOB_1_NAME_MAP,
    [RequiredDocumentJobType.ADDITIONAL_2]: BORROWER_DIMENSION_TO_ADDITIONAL_JOB_2_NAME_MAP,
};

export const BORROWER_DIMENSION_TO_BORROWER_TYPE_MAP: Record<Person, BorrowerType> = {
    kreditnehmer1: BorrowerType.KN1,
    kreditnehmer2: BorrowerType.KN2,
    kreditnehmer3: BorrowerType.KN3,
    kreditnehmer4: BorrowerType.KN4,
};

export const OBJECT_DIMENSION_TO_EXISTING_PROPERTY_ENUMERATOR_TYPE_MAP: Record<
    ObjectType,
    ExistingPropertyEnumerator | null
> = {
    object1: null,
    object2: "existingProperty1",
    object3: "existingProperty2",
    object4: "existingProperty3",
    object5: "existingProperty4",
};

export const OBJECT_DIMENSION_TO_ADDITIONAL_LIABILITY_RELATED_OBJECT_MAP: Record<ObjectType, RelatedObjectType> = {
    object1: RelatedObjectType.FINANCING_OBJECT,
    object2: RelatedObjectType.EXISTING_OBJECT_1,
    object3: RelatedObjectType.EXISTING_OBJECT_2,
    object4: RelatedObjectType.EXISTING_OBJECT_3,
    object5: RelatedObjectType.EXISTING_OBJECT_4,
};

export const BORROWER_ENUMERATOR_TO_BORROWER_DIMENSION_MAP: Record<BorrowerEnumerator, Person> = {
    Borrower1: "kreditnehmer1",
    Borrower2: "kreditnehmer2",
    Borrower3: "kreditnehmer3",
    Borrower4: "kreditnehmer4",
};

export const PERSON_DIMENSION_TO_BORROWER_ENUMERATOR_MAP: Record<Person, BorrowerEnumerator> = {
    kreditnehmer1: "Borrower1",
    kreditnehmer2: "Borrower2",
    kreditnehmer3: "Borrower3",
    kreditnehmer4: "Borrower4",
};
