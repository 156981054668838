import { ON_SEASON_CONSTRUCTION_TIMELINE } from "../../../utils/customer";
import {
    BorrowerReferralType,
    DealPropertyFinancingType,
    DealPropertyType,
    JaNeinValue,
    SurveyBankOption,
    SurveyFinancingCompletion,
    SurveyHouseBankFinancingPreference,
    SurveyHowCanWeHelpYou,
    SurveyHowSoonFinancingCommitmentNeededPurchase,
    SurveyNextStepsInPurchaseProcess,
    SurveyProperties,
    SurveyPropertyAlreadyDecided,
    SurveyPropertyUsage,
    SurveyPurchaseProgress,
    SurveyPurchaseProjectStatus,
} from "../../../models";

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.COMMERCIAL,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE,
    price: 300_000,
    availableCapital: 100_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.JA,
    propertyAlreadyDecided: JaNeinValue.JA,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.AS_SOON_AS_POSSIBLE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    nextStepsInPurchaseProcess: SurveyNextStepsInPurchaseProcess.PURCHASE_OFFER_ALREADY_MADE,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.ASAP_ALREADY_MADE_AN_OFFER,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_WRONG_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_WRONG_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.OWN_USE,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE,
    price: 300_000,
    availableCapital: 100_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.JA,
    propertyAlreadyDecided: JaNeinValue.JA,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.AS_SOON_AS_POSSIBLE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    nextStepsInPurchaseProcess: SurveyNextStepsInPurchaseProcess.PURCHASE_OFFER_ALREADY_MADE,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.ASAP_ALREADY_MADE_AN_OFFER,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.OWN_USE,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.ACTIVE_ON_SIGHTSEEING,
    price: 300_000,
    availableCapital: 100_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.NEIN,
    propertyAlreadyDecided: SurveyPropertyAlreadyDecided.NO_NOT_YET,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.NOT_SURE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.NO_CONVERSATIONS_HELD,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.NOT_YET_PURCHASE_NOT_CONFIRMED,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    firstName: "Max",
    email: "test@miracl.at",
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.OWN_USE,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.ACTIVE_ON_SIGHTSEEING,
    price: 300_000,
    availableCapital: 100_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.JA,
    propertyAlreadyDecided: JaNeinValue.JA,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.NOT_SURE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    nextStepsInPurchaseProcess: SurveyNextStepsInPurchaseProcess.STILL_THINKING_ABOUT_IT,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.NO_CONVERSATIONS_HELD,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.NOT_YET_PURCHASE_NOT_CONFIRMED,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
    firstName: "Max",
    email: "test@miracl.at",
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_LIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.OWN_USE,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.ACTIVE_ON_SIGHTSEEING,
    price: 300_000,
    availableCapital: 70_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.JA,
    propertyAlreadyDecided: JaNeinValue.JA,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.NOT_SURE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    nextStepsInPurchaseProcess: SurveyNextStepsInPurchaseProcess.STILL_THINKING_ABOUT_IT,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.NO_CONVERSATIONS_HELD,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.NOT_YET_PURCHASE_NOT_CONFIRMED,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_HIT_FORECAST_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.OWN_USE,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE,
    price: 300_000,
    availableCapital: 10_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.JA,
    propertyAlreadyDecided: JaNeinValue.JA,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.AS_SOON_AS_POSSIBLE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    nextStepsInPurchaseProcess: SurveyNextStepsInPurchaseProcess.PURCHASE_OFFER_ALREADY_MADE,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.ASAP_ALREADY_MADE_AN_OFFER,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.GOOGLE,
    hasExistingBankOffer: JaNeinValue.JA,
    existingBankOfferPayoutAmount: 300000,
    existingBankOfferDocumentPath: "/existingBankOffers/testid",
    firstName: "Max",
    email: "test@miracl.at",
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_UNFEASIBLE_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE_VARIANTS: Partial<SurveyProperties> = {
    realEstate: JaNeinValue.NEIN,
    occupation: "Angestellt",
    pax: "1 Person",
    usage: SurveyPropertyUsage.OWN_USE,
    propertyType: DealPropertyType.APARTMENT_PROPERTY_TYPE,
    progress: SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE,
    price: 300_000,
    availableCapital: 100_000,
    primaryPersonIncome: 4_500,
    propertyAlreadyVisited: JaNeinValue.JA,
    propertyAlreadyDecided: JaNeinValue.JA,
    untilWhenFinancingCompleted: SurveyFinancingCompletion.AS_SOON_AS_POSSIBLE,
    houseBankFinancingPreference: SurveyHouseBankFinancingPreference.NONE,
    firstName: "Max",
    email: "test@miracl.at",
    consentedMarketingEmails: "false",
    phone: "123123123",
    propertyAlreadyFound: JaNeinValue.JA,
    additionalInvestmentCosts: 0,
    nextStepsInPurchaseProcess: SurveyPurchaseProjectStatus.PURCHASE_OFFER_ALREADY_MADE,
    purchasePlannedAt: ON_SEASON_CONSTRUCTION_TIMELINE[0],
    purchaseProgress: SurveyPurchaseProgress.BANK_APPOINTMENTS_PENDING,
    howSoonFinancingCommitmentNeeded: SurveyHowSoonFinancingCommitmentNeededPurchase.ASAP_ALREADY_MADE_AN_OFFER,
    whichBanksAreHouseBanks: SurveyBankOption.ERSTE,
    howCanWeHelpYou: SurveyHowCanWeHelpYou.VIEW_TOP_CONDITIONS,
    referral: BorrowerReferralType.MIRACL_WORKER,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.PURCHASE,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_INTERIM_FINANCING: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.INTERIM_FINANCING,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_RETIREMENT_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.RETIREMENT_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_COOPERATIVE_APARTMENT: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_APARTMENT,
};

export const SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_COOPERATIVE_CONTRIBUTION: Partial<SurveyProperties> = {
    ...SURVEY_DEVELOPMENT_TEST_DATA_MEDIUM_PURCHASE_VARIANTS,
    intention: DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
};
