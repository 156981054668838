import {
    AppVariant,
    BorrowerEmploymentType,
    ClosedLostReasonSingleValues,
    DealPropertyFinancingType,
    DealPropertyObjectPurpose,
    SurveyBankOption,
    SurveyConstructionCostsAlreadyAvailable,
    SurveyConstructionEstimateRecieved,
    SurveyConstructionProjectStatus,
    SurveyConstructionSubmissionPlanReady,
    SurveyCustomerIntentType,
    SurveyCustomerLowIntentTypeReason,
    SurveyCustomerWrongIntentTypeReason,
    SurveyDebtRestructuringPeroid,
    SurveyFinancingCompletion,
    SurveyFinancingType,
    SurveyHouseBankFinancingPreference,
    SurveyHowCanWeHelpYou,
    SurveyHowSoonFinancingCommitmentNeededPurchase,
    SurveyHowSoonFinancingCommitmentNeededRefinancing,
    SurveyNextStepsInPurchaseProcess,
    SurveyNumberOfBorrowers,
    SurveyProperties,
    SurveyPropertyAlreadyDecided,
    SurveyPropertyAlreadyVisited,
    SurveyPropertyUsage,
    SurveyPurchaseProgress,
    SurveyPurchaseProjectStatus,
    SurveyRefinancingProjectStatus,
    TrueFalseValue,
    SurveyPropertiesKey,
    JaNeinValue,
    DealPropertyType,
} from "../../models";

import { NOT_APPLICABLE } from "../common";

export const SUBMISSION_PLAN_READY_OR_AVAILABLE_VALUES = [
    SurveyConstructionSubmissionPlanReady.YES,
    SurveyConstructionSubmissionPlanReady.FAST,
];

/**
 * MOR-2068
 *
 * Renovation and Other financing type are added for tool, but we still don't want to have them in survey.
 * For that reason we filter them out from available project type values
 */
export const SURVEY_PROJECT_TYPE_VALUES = Object.values(DealPropertyFinancingType).filter(
    (type) => ![DealPropertyFinancingType.RENOVATION, DealPropertyFinancingType.OTHER].includes(type),
);

export const SURVEY_PROPERTY_TYPE_VALUES = Object.values(DealPropertyType);

export const SURVEY_FINANCING_TYPE_VALUES = Object.values(SurveyFinancingType);

export const SURVEY_PROPERTY_USAGE_VALUES = Object.values(SurveyPropertyUsage);

export const SURVEY_PURCHASE_PROJECT_STATUS_VALUES = Object.values(SurveyPurchaseProjectStatus);

export const SURVEY_CONSTRUCTION_PROJECT_STATUS_VALUES = Object.values(SurveyConstructionProjectStatus);

export const SURVEY_REFINANCING_PROJECT_STATUS_VALUES = Object.values(SurveyRefinancingProjectStatus);

export const SURVEY_PROPERTY_TYPE_PROJECT_STATUS_MAP: Record<DealPropertyFinancingType, string[]> = {
    [DealPropertyFinancingType.PURCHASE]: SURVEY_PURCHASE_PROJECT_STATUS_VALUES,
    [DealPropertyFinancingType.CONSTRUCTION]: SURVEY_CONSTRUCTION_PROJECT_STATUS_VALUES,
    [DealPropertyFinancingType.REFINANCE]: SURVEY_REFINANCING_PROJECT_STATUS_VALUES,
    [DealPropertyFinancingType.RENOVATION]: [],
    [DealPropertyFinancingType.OTHER]: [],
    [DealPropertyFinancingType.CONSUMER_CREDIT]: [],
    [DealPropertyFinancingType.COOPERATIVE_APARTMENT]: SURVEY_PURCHASE_PROJECT_STATUS_VALUES,
    [DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION]: SURVEY_PURCHASE_PROJECT_STATUS_VALUES,
    [DealPropertyFinancingType.INTERIM_FINANCING]: SURVEY_PURCHASE_PROJECT_STATUS_VALUES,
    [DealPropertyFinancingType.RETIREMENT_APARTMENT]: SURVEY_PURCHASE_PROJECT_STATUS_VALUES,
};

export const SURVEY_OCCUPATION_TYPE_VALUES = [
    BorrowerEmploymentType.EMPLOYED,
    BorrowerEmploymentType.SELF_EMPLOYED,
    BorrowerEmploymentType.OTHER,
];

export const SURVEY_OCCUPATION_TYPE_VALUES_PURCHASE = [
    BorrowerEmploymentType.EMPLOYED,
    BorrowerEmploymentType.SELF_EMPLOYED,
    BorrowerEmploymentType.WORKER,
    BorrowerEmploymentType.CIVIL_SERVICE,
    BorrowerEmploymentType.SEASONAL,
    BorrowerEmploymentType.PENSION,
    BorrowerEmploymentType.MATERNITY_LEAVE,
    BorrowerEmploymentType.OTHER,
];

export const SURVEY_NUMBER_OF_BORROWERS_VALUES = Object.values(SurveyNumberOfBorrowers);

export const SURVEY_CONSTRUCTION_SUBMISSION_PLAN_READY_VALUES = Object.values(SurveyConstructionSubmissionPlanReady);

export const SURVEY_CONSTRUCTION_COSTS_ALREADY_AVAILABLE_VALUES = Object.values(
    SurveyConstructionCostsAlreadyAvailable,
);

export const SURVEY_CONSTRUCTION_ESTIMATE_RECIEVED_VALUES = Object.values(SurveyConstructionEstimateRecieved);

export const SURVEY_UNTIL_WHEN_FINANCING_COMPLETED_VALUES = Object.values(SurveyFinancingCompletion);

export const SURVEY_HOUSE_BANK_FINANCING_PREFERENCE_VALUES = Object.values(SurveyHouseBankFinancingPreference);

export const SURVEY_REFINANCING_DEBT_RESTRUCTURING_PERIOD_VALUES = Object.values(SurveyDebtRestructuringPeroid);

export const SURVEY_PURCHASE_PROGRESS_VALUES = Object.values(SurveyPurchaseProgress);

export const SURVEY_HOW_SOON_FINANCING_COMMITMENT_NEEDED_VALUES_PURCHASE = Object.values(
    SurveyHowSoonFinancingCommitmentNeededPurchase,
);

export const SURVEY_HOW_SOON_FINANCING_COMMITMENT_NEEDED_VALUES_REFINANCING = Object.values(
    SurveyHowSoonFinancingCommitmentNeededRefinancing,
);

export const SURVEY_HOW_CAN_WE_HELP_YOU_VALUES = Object.values(SurveyHowCanWeHelpYou);

export const SURVEY_NEXT_STEPS_IN_PURCHASE_PROCESS_VALUES = Object.values(SurveyNextStepsInPurchaseProcess);

export const SURVEY_PROPERTY_ALREADY_VISITED_VALUES = Object.values(SurveyPropertyAlreadyVisited);

export const SURVEY_PROPERTY_ALREADY_DECIDED_VALUES = Object.values(SurveyPropertyAlreadyDecided);

export const SURVEY_BANK_OPTIONS_VALUES = Object.values(SurveyBankOption);

export const DEFAULT_SURVEY_PROPERTIES_VALUES: SurveyProperties = {
    usage: "",
    intention: "",
    progress: "",
    financingType: "",
    realEstate: "",
    primaryPersonIncome: 0,
    secondaryPersonIncome: 0,
    firstName: "",
    lastName: "",
    occupation: "",
    consentedMarketingEmails: "false",
    pax: "",
    phone: "",
    customerIntent: "",
    oldCustomerIntent: "",
    email: "",
    loanAmount: 0,
    refinancingDebtLeftAmount: 0,
    refinancingDebtRemainingYears: 0,
    constructionIncludesLandPurchase: "",
    constructionCosts: 0,
    landPurchasePrice: 0,
    availableCapital: 0,
    price: 0,
    estateAgentFee: 0,
    realEstateTransferFee: 0,
    propertyRegistrationFee: 0,
    purchaseContractCreationFee: 0,
    ltv: 0,
    dsti: 0,
    surveyVersion: "",
    propertyAlreadyVisited: "",
    propertyAlreadyDecided: "",
    untilWhenFinancingCompleted: "",
    houseBankFinancingPreference: "",
    roughConstructionStartDate: "",
    submissionPlanReady: "",
    constructionCostsAlreadyAvailable: "",
    constructionEstimateRecieved: "",
    whenWasPropertyBought: "",
    currentMonthlyRate: 0,
    currentInterestRate: 0,
    currentCreditBank: "",
    talkedToLendingBank: "",
    goalOfRefinancing: "",
    referral: "",
    propertyConstructionYear: 0,
    propertyLandArea: 0,
    propertyLivingSpaceArea: 0,
    propertyValueEstimate: 0,
    user_id: "",
    hs_google_click_id: "",
    hs_facebook_click_id: "",
    utm_campaign: "",
    utm_content: "",
    utm_medium: "",
    utm_source: "",
    utm_term: "",
    taskName: "",
    dealCreateHandled: TrueFalseValue.FALSE,
    cooperativeApartment: TrueFalseValue.FALSE,
    propertyCompleted: TrueFalseValue.FALSE,
    topCondition: 0,
    createAppointmentData: "",
    userSource: NOT_APPLICABLE,
    userSourceVersion: NOT_APPLICABLE,

    howSoonFinancingCommitmentNeeded: "",
    purchaseProgress: "",
    whichBanksAreHouseBanks: "",
    whichBanksAreHouseBanksOther: "",
    propertyAlreadyFound: "",
    howCanWeHelpYou: "",
    howCanWeHelpYouOther: "",
    additionalInvestmentCosts: 0,
    existingPropertyOutstandingLoanAmount: 0,
    valueOfExistingProperty: 0,
    existingPropertyZipcode: 0,
    nextStepsInPurchaseProcess: "",
    nextStepsInPurchaseProcessOther: "",

    primaryPersonNumberOfSalaries: 0,
    secondaryPersonNumberOfSalaries: 0,
    secondaryPersonOccupation: "",

    ternaryPersonIncome: 0,
    ternaryPersonNumberOfSalaries: 0,
    ternaryPersonOccupation: "",
    variant: AppVariant.ORIGIN,
    originalPurchaseOrConstructionPrice: 0,
    purchasePlannedAt: "",
    solutionId: "",
    bookAppointmentDate: "",
    bookAppointmentSlot: "",
    propertyType: "",
    hasExistingBankOffer: JaNeinValue.NEIN,
    existingBankOfferPayoutAmount: 0,
    existingBankOfferDocumentPath: "",
    salutation: "",
};

export const CUSTOMER_WRONG_INTENT_TYPE_REASON_MAP: Record<
    SurveyCustomerWrongIntentTypeReason,
    ClosedLostReasonSingleValues
> = {
    [SurveyCustomerWrongIntentTypeReason.COMMERCIAL_USAGE]: ClosedLostReasonSingleValues.COMMERCIAL_LOAN,
    [SurveyCustomerWrongIntentTypeReason.COMMERCIAL_CREDIT]: ClosedLostReasonSingleValues.COMMERCIAL_LOAN,
    [SurveyCustomerWrongIntentTypeReason.CONSUMER_CREDIT]: ClosedLostReasonSingleValues.CONSUMER_LOAN,
    [SurveyCustomerWrongIntentTypeReason.OTHER_PROJECT_TYPE]: ClosedLostReasonSingleValues.OTHER,
    [SurveyCustomerWrongIntentTypeReason.MISCELLANEOUS]: ClosedLostReasonSingleValues.OTHER,
};

export const CUSTOMER_LOW_INTENT_TYPE_REASON_MAP: Record<
    SurveyCustomerLowIntentTypeReason,
    ClosedLostReasonSingleValues
> = {
    [SurveyCustomerLowIntentTypeReason.LTV]: ClosedLostReasonSingleValues.TOO_LITTLE_DOWNPAYMENT_LTI_DSTI_KSV,
    [SurveyCustomerLowIntentTypeReason.DSTI]: ClosedLostReasonSingleValues.TOO_LITTLE_DOWNPAYMENT_LTI_DSTI_KSV,
    [SurveyCustomerLowIntentTypeReason.NO_VISIT_OR_COMPLETE]: ClosedLostReasonSingleValues.NO_LEAD,
    [SurveyCustomerLowIntentTypeReason.MISCELLANEOUS]: ClosedLostReasonSingleValues.OTHER,
};

/**
 * MOR-1466: part 4 (Prefill “Art der Finanzierung” and “Objektnutzung” based on Survey values)
 */
export const SURVEY_USAGE_TO_DEAL_USAGE_MAP: Record<SurveyPropertyUsage, DealPropertyObjectPurpose> = {
    [SurveyPropertyUsage.OWN_USE]: DealPropertyObjectPurpose.OWN_USE,
    [SurveyPropertyUsage.RENT_OUT]: DealPropertyObjectPurpose.RENT_OUT,
    [SurveyPropertyUsage.COMMERCIAL]: DealPropertyObjectPurpose.OWN_USE, // no other sensible default
};

export const SURVEY_COOPERATIVE_INTENT_TYPES: DealPropertyFinancingType[] = [
    DealPropertyFinancingType.COOPERATIVE_APARTMENT,
    DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION,
];

export const PROJECT_TYPES_WITH_PROPERTY_TYPE: DealPropertyFinancingType[] = [
    DealPropertyFinancingType.PURCHASE,
    DealPropertyFinancingType.REFINANCE,
    DealPropertyFinancingType.CONSTRUCTION,
    DealPropertyFinancingType.INTERIM_FINANCING,
];

export const NUMBER_OF_BORROWERS_OPTION_TO_COUNT_MAP: Record<SurveyNumberOfBorrowers, number> = {
    [SurveyNumberOfBorrowers.ONE]: 1,
    [SurveyNumberOfBorrowers.TWO]: 2,
    [SurveyNumberOfBorrowers.THREE]: 3,
};

const ALL_SURVEY_CUSTOMER_INTENT_TYPES = Object.values(SurveyCustomerIntentType);

export const SURVEY_INTENTION_TO_CUSTOMER_INTENT_OPTIONS_MAP: Record<
    DealPropertyFinancingType,
    SurveyCustomerIntentType[]
> = {
    [DealPropertyFinancingType.PURCHASE]: ALL_SURVEY_CUSTOMER_INTENT_TYPES,
    [DealPropertyFinancingType.INTERIM_FINANCING]: ALL_SURVEY_CUSTOMER_INTENT_TYPES,
    [DealPropertyFinancingType.RETIREMENT_APARTMENT]: ALL_SURVEY_CUSTOMER_INTENT_TYPES,
    [DealPropertyFinancingType.COOPERATIVE_APARTMENT]: ALL_SURVEY_CUSTOMER_INTENT_TYPES,
    [DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION]: ALL_SURVEY_CUSTOMER_INTENT_TYPES,
    [DealPropertyFinancingType.REFINANCE]: [
        SurveyCustomerIntentType.WRONG,
        SurveyCustomerIntentType.UNFEASIBLE,
        SurveyCustomerIntentType.LOW,
        SurveyCustomerIntentType.MEDIUM,
        SurveyCustomerIntentType.HIGH,
    ],
    [DealPropertyFinancingType.RENOVATION]: [],
    [DealPropertyFinancingType.CONSTRUCTION]: [
        SurveyCustomerIntentType.WRONG,
        SurveyCustomerIntentType.UNFEASIBLE,
        SurveyCustomerIntentType.LOW,
        SurveyCustomerIntentType.MEDIUM,
        SurveyCustomerIntentType.HIGH,
    ],
    [DealPropertyFinancingType.CONSUMER_CREDIT]: [SurveyCustomerIntentType.WRONG],
    [DealPropertyFinancingType.OTHER]: [],
};

/**
 * MOR-1968: Incomplete data synced to Hubspot after Survey submit
 * Sentry reporting: To detect anomalies in contact/deal POST/PUT requests,
 * I added a list of fields that are the bare minimum to 100% be expected in the body
 * In the useContactUpdate hook, we will check the fields in the request and verify
 * that the object contains the bare minimum. Otherwise we log it to sentry.
 * I went with a "bare minimum" approach and not a total possible combinations one,
 * because it would be hard to track all potential branching,
 * (sometimes some fields are necessary and sometimes not)
 */
const MINIMUM_REQUIRED_SURVEY_PROPERTIES_WRONG_INTENTS: SurveyPropertiesKey[] = ["intention", "ltv", "dsti"];

const MINIMUM_REQUIRED_SURVEY_PROPERTIES_DEFAULT: SurveyPropertiesKey[] = ["intention", "progress", "ltv", "dsti"];

export const MINIMUM_REQUIRED_SURVEY_PROPERTIES_PURCHASE: SurveyPropertiesKey[] = [
    ...MINIMUM_REQUIRED_SURVEY_PROPERTIES_DEFAULT,
    "usage",
    "loanAmount",
    "purchaseProgress",
    "propertyAlreadyVisited",
];

export const MINIMUM_REQUIRED_SURVEY_PROPERTIES_REFINANCING: SurveyPropertiesKey[] = [
    ...MINIMUM_REQUIRED_SURVEY_PROPERTIES_DEFAULT,
    "currentInterestRate",
    "financingType",
    "refinancingDebtLeftAmount",
    "goalOfRefinancing",
    "currentMonthlyRate",
    "refinancingDebtRemainingYears",
];

export const MINIMUM_REQUIRED_SURVEY_PROPERTIES_CONSTRUCTION: SurveyPropertiesKey[] = [
    ...MINIMUM_REQUIRED_SURVEY_PROPERTIES_DEFAULT,
    "usage",
    "constructionCosts",
    "constructionIncludesLandPurchase",
    "submissionPlanReady",
    "constructionCostsAlreadyAvailable",
    "constructionEstimateRecieved",
    "roughConstructionStartDate",
];

export const SURVEY_INTENTION_TO_MINIMUM_REQUIRED_FIELDS_MAP: Record<DealPropertyFinancingType, SurveyPropertiesKey[]> =
    {
        [DealPropertyFinancingType.PURCHASE]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_PURCHASE,
        [DealPropertyFinancingType.INTERIM_FINANCING]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_PURCHASE,
        [DealPropertyFinancingType.RETIREMENT_APARTMENT]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_PURCHASE,
        [DealPropertyFinancingType.COOPERATIVE_APARTMENT]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_PURCHASE,
        [DealPropertyFinancingType.COOPERATIVE_CONTRIBUTION]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_PURCHASE,
        [DealPropertyFinancingType.REFINANCE]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_REFINANCING,
        [DealPropertyFinancingType.RENOVATION]: [],
        [DealPropertyFinancingType.CONSTRUCTION]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_CONSTRUCTION,
        [DealPropertyFinancingType.CONSUMER_CREDIT]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_WRONG_INTENTS,
        [DealPropertyFinancingType.OTHER]: MINIMUM_REQUIRED_SURVEY_PROPERTIES_WRONG_INTENTS,
    };
